import React from 'react';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import RoutePaths from 'src/config/RoutePaths';
import LayoutWrapper from 'src/shared/components/LayoutWrapper';
import Section from 'src/shared/components/Section';
import RoundedButton from 'src/shared/components/RoundedButton';

import styles from './styles.module.scss';

export const getStaticProps = async ({ locale }) => {
    return {
        props: {
            ...(await serverSideTranslations(locale ?? 'en', ['common'])),
        },
    };
};

const Custom404 = () => {
    const { t } = useTranslation();

    return (
        <LayoutWrapper hideMeta hideMenu className={styles.container} headerProps={{ type: 'black' }}>
            <Section className={styles.section}>
                <p className={styles.title}>404</p>
                <p className={styles.content}>{t('page_not_found')}</p>
                <RoundedButton href={RoutePaths.HOME}>{t('back_to_homepage')}</RoundedButton>
            </Section>
        </LayoutWrapper>
    );
};

export default Custom404;
